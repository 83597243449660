@font-face {
  font-family: 'FZ-Poppins-Black';
  src:
    local('FZ-Poppins-Black'),
    url('../../fonts/FZ-Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-BlackItalic';
  src:
    local('FZ-Poppins-BlackItalic'),
    url('../../fonts/FZ-Poppins-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-Bold';
  src:
    local('FZ-Poppins-Bold'),
    url('../../fonts/FZ-Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-BoldItalic';
  src:
    local('FZ-Poppins-BoldItalic'),
    url('../../fonts/FZ-Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-ExtraBold';
  src:
    local('FZ-Poppins-ExtraBold'),
    url('../../fonts/FZ-Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-ExtraBoldItalic';
  src:
    local('FZ-Poppins-ExtraBoldItalic'),
    url('../../fonts/FZ-Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-ExtraLight';
  src:
    local('FZ-Poppins-ExtraLight'),
    url('../../fonts/FZ-Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-ExtraLightItalic';
  src:
    local('FZ-Poppins-ExtraLightItalic'),
    url('../../fonts/FZ-Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-Italic';
  src:
    local('FZ-Poppins-Italic'),
    url('../../fonts/FZ-Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-Light';
  src:
    local('FZ-Poppins-Light'),
    url('../../fonts/FZ-Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-LightItalic';
  src:
    local('FZ-Poppins-LightItalic'),
    url('../../fonts/FZ-Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-Medium';
  src:
    local('FZ-Poppins-Medium'),
    url('../../fonts/FZ-Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-MediumItalic';
  src:
    local('FZ-Poppins-MediumItalic'),
    url('../../fonts/FZ-Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-Regular';
  src:
    local('FZ-Poppins-Regular'),
    url('../../fonts/FZ-Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-SemiBold';
  src:
    local('FZ-Poppins-SemiBold'),
    url('../../fonts/FZ-Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-SemiBoldItalic';
  src:
    local('FZ-Poppins-SemiBoldItalic'),
    url('../../fonts/FZ-Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-Thin';
  src:
    local('FZ-Poppins-Thin'),
    url('../../fonts/FZ-Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'FZ-Poppins-ThinItalic';
  src:
    local('FZ-Poppins-ThinItalic'),
    url('../../fonts/FZ-Poppins-ThinItalic.ttf') format('truetype');
}

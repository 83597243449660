.productGrid {
  // -webkit-column-count: 4;
  // -moz-column-count: 4;
  column-count: 4;
  // -webkit-column-width: 25%;
  // -moz-column-width: 25%;
  // column-width: 25%;
}

.filterContainer1 {
  flex: 1;
}

.filterContainer2 {
  flex: 1;
  margin-bottom: 30px;
  display: none;
}

.filterButtonWrapper {
  display: flex;
  justify-content: space-between;
}

.filterButton {
  height: 32px;
  aspect-ratio: 1;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.2s linear;
  color: #1a1668;

  &:hover {
    background-color: #eb3c23;
    cursor: pointer;
    color: white;
  }
}

.filterButtonCollapse {
  color: white;
  background-color: #eb3c23;
}

@media only screen and (max-width: 769px) {
  .filterButtonWrapper {
    display: none;
  }

  .filterContainer1 {
    display: none;
  }

  .filterContainer2 {
    display: block;
  }

  .contentContainer {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1280px) {
  .productGrid {
    // -webkit-column-count: 3;
    // -moz-column-count: 3;
    column-count: 3;
    // -webkit-column-width: 33.33%;
    // -moz-column-width: 33.33%;
    // column-width: 33.33%;
  }
}
@media only screen and (max-width: 991px) {
  .productGrid {
    // -webkit-column-count: 2;
    // -moz-column-count: 2;
    column-count: 2;
    // -webkit-column-width: 50%;
    // -moz-column-width: 50%;
    // column-width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .productGrid {
    // -webkit-column-count: 1;
    // -moz-column-count: 1;
    column-count: 1;
    // -webkit-column-width: 100%;
    // -moz-column-width: 100%;
    // column-width: 100%;
  }
}

.container {
  margin-right: 16px;
  align-self: center;
  width: 120px;
  border: none !important;

  :global {
    .ant-select-selector {
      border: none !important;
    }

    .ant-select-selection-item {
      font-weight: 500;
      color: var(--secondary);
      font-size: 16px;
      font-family: var(--font-family-title);
      text-align: center;
    }
  }
}

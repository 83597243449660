.cursor-pointer {
  cursor: pointer;
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.fade-in {
  animation: fade-in-animation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-in-2s {
  animation: fade-in-animation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-in-3s {
  animation: fade-in-animation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.truncate-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.social-icon-button {
  border: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 4px;

  box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
  -moz-box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@keyframes fade-in-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 40px 0px 40px;

  .subContainer {
    //
  }
}

@media only screen and (max-width: 991px) {
  .container {
    padding: 80px 16px 0px 16px;

    .subContainer {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 575px) {
  .container {
    padding: 32px 16px 0px 16px;

    .subContainer {
      width: 100%;
    }
  }
}

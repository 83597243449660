.modalWrapper {
  padding-bottom: 0px;
  width: 40% !important;

  .container {
    margin: 0px 0px;
    min-height: 110px;

    .searchInput {
      height: 40px;
      border-radius: 40px;
      padding: 0px 16px;
      margin-bottom: 20px;
    }

    .searchIcon {
      width: 18px;
      margin-right: 8px;
      color: var(--primary);
    }

    .xIcon {
      width: 12px;
      color: rgba($color: #000000, $alpha: 0.4);
      cursor: pointer;
    }

    .searchResultContainer {
      max-height: 500px;
      overflow: auto;

      p {
        margin-bottom: 0px;
      }

      .placeHolder {
        color: rgba($color: #000000, $alpha: 0.35);
        align-self: center;
      }

      .trendingKeywordContainer {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
        font-weight: bold;
        color: rgba($color: #000000, $alpha: 0.5);

        .trendingKeyword {
          margin-right: 8px;
          margin-bottom: 4px;
          padding: 4px 8px;
          border-radius: 4px;
          font-style: italic;
          cursor: pointer;

          &:hover {
            transition: 0.2s linear;
            font-weight: bold;
            background-color: rgba(234, 164, 81, 0.75);
            color: rgba($color: #000000, $alpha: 1);
          }
        }
      }

      .sectionContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        .title {
          color: var(--primary);
          font-weight: bold;
          margin-bottom: 8px;
        }

        .sectionItem {
          margin: 0px 16px 16px 8px;
          overflow: hidden;
          border-radius: 8px;
          display: flex;
          align-items: flex-start;
          column-gap: 8px;
          cursor: pointer;
          background-color: white;
          padding: 8px;

          .thumbnail {
            width: 160px;
            border-radius: 4px;
            height: auto;
          }

          &:hover {
            box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
            -webkit-box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
            -moz-box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
          }

          p {
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
    }
  }

  :global {
    .ant-modal-content {
      background-color: #f5f6f7;
    }
  }
}

:global {
  .ant-select {
    &:focus-within,
    &:hover {
      .ant-select-selector {
        border-color: var(--primary) !important;
      }
    }
  }

  .ant-select-dropdown {
    .ant-select-item-option {
      .ant-select-item-option-content {
        font-weight: bold;
      }
    }

    .ant-select-item-option-active {
      background-color: rgba(234, 164, 81, 0.75) !important;

      svg {
        color: white !important;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .modalWrapper {
    width: 60% !important;
  }
}

@media only screen and (max-width: 991px) {
  .modalWrapper {
    width: 80% !important;
  }
}

@media only screen and (max-width: 575px) {
  .modalWrapper {
    width: 90% !important;
  }
}

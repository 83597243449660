.header {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  z-index: 99;

  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.04);

  .leftHeader {
    display: flex;
    align-items: center;
    column-gap: 32px;

    .navBarContainer {
      .routeName {
        color: var(--title);
        font-size: 15px;
        padding: 0px 15px;
        font-family: var(--font-family-title);
        font-weight: 600;
      }

      .routeName:hover {
        color: var(--primary);
      }
    }
  }

  .rightHeader {
    display: flex;
    align-items: center;

    .searchInputWrapper {
      flex: 1;
      margin-right: 26px;

      .searchInput {
        height: 40px;
        border-radius: 40px;
        padding: 0px 16px;
      }

      .magnifyingGlass {
        width: 18px;
        color: var(--primary);
      }
    }

    .searchIcon {
      display: none;
      color: var(--primary);
    }

    .extraNav {
      //
    }

    .getInTouchButton {
      //
    }
  }

  .navToggleButton {
    display: none;
    margin: 0;
  }
}

:global {
  .ant-drawer-body {
    nav {
      display: flex;
      flex-direction: column;

      h1 {
        color: var(--title);
        font-size: 15px;
        padding: 15px 0px;
        font-family: var(--font-family-title);
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 0;
        border-bottom: 1px solid #eee;
      }

      h1:hover {
        color: var(--primary);
      }
    }

    .dz-social-icon {
      padding-top: 20px;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      column-gap: 16px;

      a {
        width: 40px;
        height: 40px;
        padding: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        line-height: 38px;
        text-align: center;
        border-radius: var(--border-radius-base);
      }
    }
  }

  .ant-input-outlined:hover {
    border-color: var(--primary);
  }

  .ant-input-outlined:focus-within {
    border-color: var(--primary);
  }
}

@media only screen and (max-width: 1140px) {
  .header {
    padding: 0px 32px;
    justify-content: space-between;

    .navBarContainer {
      display: none;
    }

    .extraNav {
      display: none;
    }

    .getInTouchButton {
      display: none;
    }

    .navToggleButton {
      display: block;
    }
  }
}

@media only screen and (max-width: 575px) {
  .header {
    .rightHeader {
      .searchInputWrapper {
        display: none;
      }

      .getInTouchButton {
        display: none;
      }

      .searchIcon {
        margin-right: 24px;
        display: block;
      }
    }
  }
}

.container {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.5s;

  .thumbnail {
    object-fit: cover;
    aspect-ratio: 16/9;
    width: 100%;
    // filter: blur(1.2px);
  }

  .overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: end;
    padding: 16px;
  }

  .topicName {
    font-size: 15px;
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
  }
}

.container:hover {
  transform: scale(105%);
  cursor: pointer;
}

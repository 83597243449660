.container {
  margin: 0px 32px;
}

.slickArrow {
  font-size: 24;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  position: absolute;
  top: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  background-color: var(--primary);

  i {
    color: white;
  }
}

.deleteAccountModalViewContainer {
  display: flex;
  flex-direction: column;

  .warningTitle {
    font-size: 18px;
    font-weight: 'bold';
    margin-bottom: 0px;
    color: black;
  }

  .warningDescription {
    font-size: 14px;
    opacity: 0.6;
    margin-bottom: 32px;
  }

  .cancelButton {
    border: none;
    background-color: transparent;
    font-size: 16px;
  }

  .deleteButton {
    border: none;
    font-size: 16px;
    background-color: var(--primary);
    color: white;
    padding: 8px 24px;
    border-radius: 6px;
    font-weight: bold;
    transition: 0.2s all linear;
    min-width: 80px;

    &:hover {
      background-color: var(--bs-primary);
    }
  }
}

.container {
  position: relative;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 16px;

  .thumbnail {
    width: 100%;
    border-radius: 6px;
  }

  .productContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 8px;

    .bookmarkButton {
      visibility: hidden;
    }

    .productFooter {
      visibility: hidden;
    }

    .tagWrapper {
      display: flex;
      margin-bottom: 8px;
      visibility: hidden;
      flex-direction: column;

      .tag {
        color: var(--primary);
        margin-right: 5px;
        font-size: 14px;
        background-color: rgba($color: white, $alpha: 1);
        padding: 4px 6px;
        font-weight: bold;
        border-radius: 4px;
      }
    }

    .productFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
    }
  }

  .productContent:hover {
    background-color: rgba($color: #000000, $alpha: 0.4);
    backdrop-filter: blur(0.8px);
    cursor: pointer;
    visibility: visible;

    .bookmarkButton {
      visibility: visible;
    }

    .tagWrapper {
      visibility: visible;
    }

    .productFooter {
      visibility: visible;
    }
  }
}

.container:hover {
  box-shadow: 0px 70px 60px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.container {
  .bannerWrapper {
    column-gap: 16px;
    row-gap: 16px;

    .productName1 {
      display: none;
    }

    .downloadButtonWrapper {
      flex: 1;
      .textProductDetail {
        display: inline-block;
        padding: 15px 0px;
        color: var(--secondary);
        font-weight: 700;
        font-size: 16px;
        position: relative;
        line-height: 1.2;
      }

      .productTopicName {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .relatedProductsContainer {
    display: flex;
    flex-wrap: wrap;

    .relatedProduct {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      flex: 1;
      overflow: hidden;
      margin: 8px;
      max-width: calc(25% - 16px);
      box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
      -webkit-box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
      -moz-box-shadow: 0px 0px 4px 0px rgba(234, 164, 81, 0.75);
      cursor: pointer;

      .thumbnailWrapper {
        width: '100%';
        aspect-ratio: 16 / 9;
        overflow: hidden;
        backdrop-filter: blur(50px);
        background-color: rgba($color: #000, $alpha: 0.1);

        .thumbnail {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .relatedProductInfo {
        padding: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .container {
    .bannerWrapper {
      flex-direction: column;

      .productName1 {
        display: block;
      }
    }

    .productName2 {
      display: none;
    }

    .relatedProductsContainer {
      .relatedProduct {
        width: 100%;
        max-width: calc(50% - 16px) !important;
        flex-basis: 300px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .container {
    .relatedProductsContainer {
      .relatedProduct {
        max-width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 461px) {
  .container {
    .bannerWrapper {
      flex-wrap: wrap;

      .downloadButtonWrapper {
        margin: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 413px) {
  .container {
    .bannerWrapper {
      flex-wrap: wrap;

      .downloadButtonWrapper {
        margin: 16px 0px 0px 0px !important;
      }
    }
  }
}
